.spinning-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 1001;
}

.spinning-logo {
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
}

.loading-text {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.button-spinner {
    width: 20px;
    height: 20px;
    animation: spin 1.5s linear infinite;
    margin-right: 8px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.warning-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 1.5rem;
    line-height: 1.8;
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.loading-indicator {
    display: flex;
    gap: 6px;
    margin-top: 0.5rem;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    animation: pulse 1.5s ease-in-out infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
} 