:root {
  --bg-color: #e6f0ff;
  --text-color: #333;
  --primary-color: #3a73e1;
  --secondary-color: #fff;
  --gradient-color: linear-gradient(135deg, #3a73e1, #2e59b8);
}

.landingPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero 섹션 */
.heroSection {
  width: 100%;
  padding: 100px 0;
  background-color: #2E74FF;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.heroContent {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.heroTitle {
  font-size: 3.5em;
  font-weight: 800;
  margin-bottom: 30px;
  line-height: 1.3;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.heroDescription {
  font-size: 1.4em;
  margin-bottom: 40px;
  line-height: 1.6;
  font-weight: 500;
  color: #ffffff;
}

.heroButton {
  background-color: #ffffff;
  color: #2E74FF;
  padding: 18px 50px;
  border: none;
  border-radius: 35px;
  font-size: 1.3em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.heroButton span {
  display: inline-block;
  opacity: 0;
  animation: textSlideUp 0.5s forwards;
}

.heroButton span:nth-child(1) { animation-delay: 0.1s; }
.heroButton span:nth-child(2) { animation-delay: 0.2s; }
.heroButton span:nth-child(3) { animation-delay: 0.3s; }
.heroButton span:nth-child(4) { animation-delay: 0.4s; }
.heroButton span:nth-child(5) { animation-delay: 0.5s; }
.heroButton span:nth-child(6) { animation-delay: 0.6s; }
.heroButton span:nth-child(7) { animation-delay: 0.7s; }
.heroButton span:nth-child(8) { animation-delay: 0.8s; }

@keyframes textSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px) translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

.heroButton:hover {
  background-color: #f0f0f0;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.heroIcons {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
}

.heroLeftIcons, .heroRightIcons {
  display: flex;
  align-items: flex-end;
}

.heroLeftImage {
  max-width: 450px;
  height: auto;
}

.heroRightIcon {
  width: 400px;
  height: auto;
}

.heroBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.animatedShape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: float 15s infinite;
}

.animatedShape:nth-child(1) {
  width: 100px;
  height: 100px;
  left: 10%;
  top: 20%;
}

.animatedShape:nth-child(2) {
  width: 150px;
  height: 150px;
  right: 20%;
  top: 40%;
  animation-delay: 2s;
}

.animatedShape:nth-child(3) {
  width: 80px;
  height: 80px;
  left: 30%;
  bottom: 30%;
  animation-delay: 4s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-70px) rotate(180deg);
  }
}

.heroContainer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heroText {
  flex: 1;
  padding-right: 50px;
}

.heroText h1 {
  font-size: 4em;
  font-weight: 600;
  margin-bottom: 20px;
}

.heroImages {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.heroImages img {
  max-width: 100%;
  height: auto;
}

/* Sliding Images 섹션 */
.slidingImagesSection {
  width: 100%;
  padding-top: 50px;  /* 상단에만 50px 패딩 적용 */
  background-color: var(--bg-color);
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.slidingImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
  position: relative;
}

.slidingImages img {
  width: 300%;
  position: absolute;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.slidingImages img:nth-child(1) {
  top: 0;
  left: -100%;
  animation-name: slide-in-left;
}

.slidingImages img:nth-child(2) {
  left: 100%;
  animation-name: slide-in-right;
}

@keyframes slide-in-left {
  0%, 100% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
}

@keyframes slide-in-right {
  0%, 100% {
    left: 100%;
  }
  50% {
    left: -100%;
  }
}

/* Features 섹션 */
.features {
  width: 100%;
  padding: 50px 0 100px 0;
  background: var(--gradient-color);
  text-align: center;
  position: relative;
  overflow: hidden;
  color: white;
}

.features::before {
  content: '';
  position: absolute;
  top: -100px;
  left: -100px;
  width: 300px;
  height: 300px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: movePattern 15s linear infinite;
}

@keyframes movePattern {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(400px, 400px);
  }
}

.features h2 {
  font-size: 3em;
  color: white;

  margin-bottom: 20px;
}

.features h3 {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.features p {
  font-size: 1.2em;
  color: #f0f4ff;
  line-height: 1.6;
  margin-bottom: 60px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  margin-top: 50px;
}

.featureItem {
  background: linear-gradient(135deg, #ffffff, #e9f0ff);
  border-radius: 20px;
  padding: 40px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.featureItem::before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: rgba(58, 115, 225, 0.1);
  border-radius: 50%;
  transition: 0.4s;
  z-index: -1;
}

.featureItem:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.featureItem:hover::before {
  background: rgba(58, 115, 225, 0.2);
}

.featureItem h4 {
  font-size: 1.8em;
  color: #3a73e1;
  margin-bottom: 15px;
}

.featureItem p {
  font-size: 1.1em;
  color: #777;
  line-height: 1.6;
  text-align: center;
}

.featureItem .icon {
  width: 50px;
  height: 50px;
  color: #3a73e1;
  margin-top: 20px;
  transition: transform 0.4s ease;
}

.featureItem:hover .icon {
  transform: scale(1.2);
}

.badge {
  background-color: #3a73e1;
  color: #fff;
  padding: 5px 15px;
  font-size: 0.9em;
  border-radius: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: 0 4px 10px rgba(58, 115, 225, 0.3);
}

/* Stats 섹션 */
.stats {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #4285F4;
  margin-bottom: 10px;
}

.sectionSubtitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 50px;
}

.reasonGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.reasonItem {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.reasonItem:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.reasonIcon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.reasonItem:hover .reasonIcon {
  animation: rotateIcon 2s infinite linear;
}

.reasonItem {
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.reasonItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(46, 116, 255, 0.1);
  border-color: #2E74FF;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(90deg) scale(1.1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  75% {
    transform: rotate(270deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.reasonTitle {
  color: #2E74FF;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 15px 0;
}

.reasonDescription {
  color: #666;
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.reasonDetails {
  color: #888;
  font-size: 0.9rem;
  line-height: 1.6;
}

/* CTA 섹션 */
.ctaSection {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #2E74FF 0%, #1E54CF 100%);
}

.ctaContainer {
  position: relative;
  z-index: 2;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ctaContent {
  position: relative;
  z-index: 2;
  flex: 1.2;
  padding: 80px 60px;
  margin: 0 60px;
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ctaLeftIcons, .ctaRightIcons {
  flex: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.ctaLeftIcons img, .ctaRightIcons img {
  width: 100%;
  max-width: 500px;  /* 이미지 최대 크기 증가 */
  height: auto;
  object-fit: contain;
  opacity: 0.9;  /* 투명도 약간 증가 */
  filter: drop-shadow(0 4px 12px rgba(0,0,0,0.2));
}

.ctaLeftIcons {
  justify-content: flex-start;
  left: -50px;  /* 왼쪽 이미지를 더 왼쪽으로 */
}

.ctaRightIcons {
  justify-content: flex-end;
  right: -50px;  /* 오른쪽 이미지를 더 오른쪽으로 */
}

.ctaLogo {
  width: 180px;
  margin-bottom: 30px;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
  animation: logoFloat 3s ease-in-out infinite;
}

.ctaText {
  color: white;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.ctaButton {
  background: white;
  color: #2E74FF;
  padding: 20px 60px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 25px rgba(0,0,0,0.2);
}

.ctaButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.3);
}

@media (max-width: 768px) {
  .ctaSection {
    padding: 80px 0;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, #2E74FF 0%, #1E54CF 100%);
  }

  .ctaContainer {
    position: relative;
    z-index: 2;
    padding: 0 20px;  /* 좌우 여백 추가 */
  }

  .ctaContent {
    position: relative;
    z-index: 2;
    padding: 80px 20px 50px;  /* 상단 패딩 증가 */
    margin: 20px;
    background: rgba(255, 255, 255, 0.12);  /* 배경 투명도 살짝 조정 */
    backdrop-filter: blur(10px);
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }

  .ctaLogo {
    width: 130px;
    margin-top: 25px;  /* 로고 위 여백 추가 */
    margin-bottom: 5px;
  }

  .ctaText {
    font-size: 18px;
    margin-bottom: 15px;  /* 텍스트 아래 여백 증가 */
    padding: 0 15px;
    word-break: keep-all;
    line-height: 1.5;
  }

  .ctaButton {
    margin-bottom: 20px;  /* 버튼 아래 여백 추가 */
  }

  .mobileBgImages {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  .mobileBgLeft {
    position: absolute;
    left: -10%;
    top: 5%;
    width: 160px;
    opacity: 0.15;
    transform: rotate(-15deg);
    animation: floatLeft 6s ease-in-out infinite;
  }

  .mobileBgRight {
    position: absolute;
    right: -10%;
    bottom: 5%;
    width: 160px;
    opacity: 0.15;
    transform: rotate(15deg);
    animation: floatRight 8s ease-in-out infinite;
  }

  .mobileShapes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  .shape1, .shape2, .shape3 {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }

  .shape1 {
    width: 100px;
    height: 100px;
    top: 10%;
    left: 10%;
    animation: float 8s ease-in-out infinite;
  }

  .shape2 {
    width: 150px;
    height: 150px;
    top: 40%;
    right: -50px;
    animation: float 12s ease-in-out infinite;
  }

  .shape3 {
    width: 80px;
    height: 80px;
    bottom: 20%;
    left: 20%;
    animation: float 10s ease-in-out infinite;
  }

  .ctaButton {
    background: white;
    color: #2E74FF;
    padding: 16px 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    width: auto;
    min-width: 200px;
    max-width: 280px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    animation: pulse 2s infinite;
  }

  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }

  @keyframes floatLeft {
    0%, 100% { transform: translate(0, 0) rotate(-15deg); }
    50% { transform: translate(-10px, -15px) rotate(-20deg); }
  }

  @keyframes floatRight {
    0%, 100% { transform: translate(0, 0) rotate(15deg); }
    50% { transform: translate(10px, -15px) rotate(20deg); }
  }

  @keyframes logoFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
}

@media (max-width: 375px) {
  .ctaSection {
    padding: 60px 0;  /* 작은 화면에서는 여백 줄임 */
  }

  .ctaContent {
    padding: 50px 15px 40px;  /* 작은 화면에서 패딩 조정 */
    margin: 15px;  /* 마진 조정 */
  }

  .ctaButton {
    font-size: 16px;
    padding: 12px 25px;
  }
  
  .ctaLogo {
    width: 120px;
  }
}

/* 펄스 애니메이션 */
.pulseButton {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* 페이드 인 애니메이션 */
.fadeInUpItem {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fadeInUp {
  opacity: 1;
  transform: translateY(0);
}

.landingPageFeaturesSection {
  padding: 80px 0;
  background-color: #ffffff;
}

.landingPageFeaturesContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.landingPageFeaturesTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #4285F4;
}

.landingPageFeaturesSubtitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
  color: #333;
}

.landingPageFeaturesList {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.landingPageFeatureItem {
  display: flex;
  align-items: stretch;
  gap: 30px;
}

.landingPageFeatureImageWrapper {
  flex: 0 0 50%;
  max-width: 500px;
  background-color: #4285F4;
  border-radius: 20px;
  overflow: hidden;
}

.landingPageFeatureImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landingPageFeatureContentWrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.landingPageFeatureContent {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.landingPageFeatureHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.landingPageFeatureAiIcon {
  background-color: #E6F3FF;
  color: #4285F4;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
}

.landingPageFeatureTitle {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.landingPageFeatureDescription {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.featuresSection {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.featuresContainer {
  max-width: 1100px;  /* 컨테이너 크기 약간 감소 */
  margin: 0 auto;
  padding: 0 20px;
}

.featuresMainTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #4285F4;
  margin-bottom: 10px;
}

.featuresSubTitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 50px;
}

.featuresListWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.featureItemCard {
  display: flex;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 280px;  /* 카드의 높이 약간 감소 */
}

.featureItemLeftSection {
  flex: 0 0 40%;  /* 이미지 영역 비율 감소 */
  max-width: 400px;  /* 최대 너비 제한 */
  background-color: #4285F4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.featureItemImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featureItemRightSection {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 30px;
}

.featureItemContent {
  width: 100%;
}

.featureItemHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.featureItemAiTag {
  background-color: #E6F3FF;
  color: #4285F4;
  padding: 5px 14px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: bold;
}

.featureItemTitle {
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.featureItemDescription {
  font-size: 15px;
  line-height: 1.5;
  color: #666;
}

.generationExamples {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.exampleTabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.exampleTab {
  padding: 12px 24px;
  border-radius: 30px;
  border: none;
  background-color: #e0e0e0;
  color: #666;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.exampleTab.activeTab {
  background-color: #E6F3FF;
  color: #4285F4;
}

.tabIcon {
  font-size: 20px;
  color: #4285F4;
}

.exampleContent {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;  /* 왼쪽 정렬 추가 */
}

.textContent {
  display: flex;
  gap: 20px;
  align-items: flex-start;  /* 아이콘을 텍스트 상단에 맞춤 */
}

.contentIcon {
  font-size: 24px;
  color: #4285F4;
  flex-shrink: 0;
  margin-top: 4px;  /* 아이콘을 약간 아래로 조정 */
}

.exampleTitle {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: left;  /* 제목도 왼쪽 정렬 */
  padding-bottom: 15px;  /* 하단 여백 추가 */
  border-bottom: 1px solid #e0e0e0
}

.exampleText {
  white-space: pre-wrap;
  line-height: 1.8;
  color: #666;
  font-size: 16px;
  margin: 0;
  text-align: left;  /* 본문 텍스트 왼쪽 정렬 */
}

.exampleImagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #f0f0f0;
  border-radius: 10px;
  font-size: 18px;
  color: #666;
}

/* FAQ 섹션 스타일 수정 */
.faqSection {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.faqList {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.faqItem {
  border-bottom: 1px solid #e0e0e0;
}

.faqItem:last-child {
  border-bottom: none;
}

.faqQuestion {
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.faqAnswer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  background-color: #ffffff;
  text-align: left;
  padding: 0;
}

.faqAnswer.open {
  max-height: 1000px;
  padding: 20px;
}

.faqAnswer p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin: 0;
}

@media (max-width: 768px) {
  .heroSection {
    padding: 40px 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .heroContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-top: 60px;
  }

  .heroTitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    word-break: keep-all;
  }

  .heroDescription {
    font-size: 16px;
    text-align: center;
    word-break: keep-all;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .heroButton {
    width: 80%;
    max-width: 280px;
    padding: 15px 0;
    font-size: 18px;
    border-radius: 30px;
    margin-bottom: 40px;
  }

  .heroIcons {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
  }

  .heroLeftImage {
    max-width: 45%;
    height: auto;
  }

  .heroRightIcon {
    max-width: 45%;
    height: auto;
  }

  /* Sliding Images 섹션 */
  .slidingImagesSection {
    padding-top: 30px;
  }

  .slidingImages {
    height: 140px;
  }

  .slidingImages img {
    width: 400%;
  }

  /* Features 섹션 */
  .featuresSection {
    padding: 40px 0;
  }

  .featuresMainTitle {
    font-size: 20px;
  }

  .featuresSubTitle {
    font-size: 22px;
    margin-bottom: 30px;
    padding: 0 20px;
  }

  .featuresListWrapper {
    gap: 20px;
  }

  .featureItemCard {
    flex-direction: column;
    height: auto;
    margin: 0 15px;
  }

  /* Generation Examples 섹션 */
  .generationExamples {
    padding: 40px 15px;
  }

  .exampleTabs {
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 10px;
  }

  .exampleTab {
    width: calc(50% - 5px);
    font-size: 14px;
  }

  /* Stats 섹션 */
  .stats {
    padding: 40px 0;
  }

  .reasonGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 15px;
  }

  .reasonItem {
    padding: 20px;
  }

  .reasonIcon {
    width: 50px;
    height: 50px;
  }

  .reasonTitle {
    font-size: 18px;
  }

  .reasonDescription {
    font-size: 14px;
  }

  /* FAQ 섹션 관련 스타일 삭제 */
  .faqQuestion,
  .faqAnswer,
  .faqToggle {
    /* 이 스타일들 삭제 */
  }

  /* CTA 섹션 */
  .ctaSection {
    padding: 40px 0;
  }

  .ctaContainer {
    padding: 0 15px;
  }
}

/* 일반 모바일 */
@media (max-width: 576px) {
  .heroTitle {
    font-size: 1.8em;
    padding: 0 10px;
  }

  .heroDescription {
    font-size: 1em;
    br {
      display: none;
    }
  }

  .heroButton {
    width: 90%;
    font-size: 1em;
  }

  /* Sliding Images 섹션 */
  .slidingImages {
    height: 120px;
  }

  .slidingImages img {
    width: 500%;
  }

  /* Features 섹션 */
  .featuresMainTitle {
    font-size: 18px;
  }

  .featuresSubTitle {
    font-size: 20px;
    br {
      display: none;
    }
  }

  .featureItemLeftSection {
    flex: 0 0 180px;
  }

  .featureItemTitle {
    font-size: 16px;
  }

  .featureItemDescription {
    font-size: 13px;
  }

  /* Generation Examples 섹션 */
  .exampleTab {
    width: 100%;
    margin-bottom: 8px;
  }

  .exampleTitle {
    font-size: 15px;
  }

  .exampleText {
    font-size: 13px;
  }

  /* Stats 섹션 */
  .reasonGrid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .reasonIcon {
    width: 40px;
    height: 40px;
  }

  .reasonTitle {
    font-size: 16px;
  }

  .reasonDescription {
    font-size: 12px;
  }

  /* CTA 섹션 */
  .ctaSection {
    padding: 30px 0;
  }

  .ctaContent {
    padding: 0 10px;
  }

  .ctaButton {
    width: 90%;
    max-width: 280px;
    font-size: 15px;
  }
}

/* 작은 모바일 */
@media (max-width: 375px) {
  .heroTitle {
    font-size: 20px;
  }

  .heroDescription {
    font-size: 14px;
  }

  .heroButton {
    font-size: 16px;
    padding: 12px 0;
  }

  /* Sliding Images 섹션 */
  .slidingImages {
    height: 100px;
  }

  /* Features 섹션 */
  .featuresMainTitle {
    font-size: 16px;
  }

  .featuresSubTitle {
    font-size: 18px;
  }

  .featureItemLeftSection {
    flex: 0 0 160px;
  }

  .featureItemTitle {
    font-size: 15px;
  }

  .featureItemDescription {
    font-size: 12px;
    line-height: 1.4;
  }

  /* Generation Examples 섹션 */
  .exampleTitle {
    font-size: 14px;
  }

  .exampleText {
    font-size: 12px;
    line-height: 1.4;
  }

  /* Stats 섹션 */
  .reasonItem {
    padding: 15px;
  }

  .reasonIcon {
    width: 35px;
    height: 35px;
  }

  .reasonTitle {
    font-size: 15px;
  }

  .reasonDescription {
    font-size: 12px;
  }

  .reasonDetails {
    font-size: 11px;
    line-height: 1.4;
  }

  /* CTA 섹션 */
  .ctaButton {
    padding: 10px 20px;
    font-size: 14px;
  }

  .ctaLogo {
    width: 120px;
  }
}

/* 모바일 생성 예시 슬라이더 스타일 */
.mobileExampleSlider {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.mobileExampleSlides {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

.mobileExampleSlide {
  flex: 0 0 100%;
  padding: 20px;
  box-sizing: border-box;
}

.mobileExampleDots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ddd;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activeDot {
  background-color: #2E74FF;
  width: 24px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .exampleTabs {
    display: none;
  }

  .mobileExampleSlide {
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .exampleTitle {
    font-size: 16px;
    margin-bottom: 12px;
    color: #2E74FF;
  }

  .exampleText {
    font-size: 14px;
    line-height: 1.6;
    color: #4a5568;
  }
}

@media (max-width: 480px) {
  .heroTitle {
    font-size: 1.8em;
  }

  .heroDescription {
    font-size: 1em;
  }

  .heroLeftImage {
    max-width: 150px;
  }

  .heroRightIcon {
    width: 130px;
  }
}

.mobileReasonSlider {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}

.mobileReasonSlides {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  margin: 0 -10px;
}

.mobileReasonSlide {
  flex: 0 0 100%;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  box-sizing: border-box;
  width: calc(100% - 20px);
}

.reasonIcon {
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  object-fit: contain;
}

.reasonTitle {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
  word-break: keep-all;
  line-height: 1.4;
}

.reasonDescription {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #2E74FF;
  padding: 8px 20px;
  border-radius: 30px;
  margin-bottom: 20px;
  display: inline-block;
  word-break: keep-all;
}

.reasonDetails {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  word-break: keep-all;
  padding: 0 10px;
}

.mobileReasonDots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

@media (max-width: 375px) {
  .mobileReasonSlide {
    padding: 30px 16px;
  }

  .reasonIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  .reasonTitle {
    font-size: 18px;
  }

  .reasonDescription {
    font-size: 14px;
    padding: 6px 16px;
  }

  .reasonDetails {
    font-size: 13px;
  }
}

.mobileHeroSection {
  padding: 40px 40px 50px;
  background-color: #2E74FF; /* 기존 PC 색상으로 변경 */
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -60px; /* 헤더 높이만큼 위로 올림 */
  padding-top: 80px; /* 헤더 높이 + 여백 */
}

.mobileHeroContent {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mobileHeroLogo {
  width: 140px;
  height: auto;
  margin-top: 18px;
  margin-bottom: 10px;
}

.mobileHeroTitle {
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  margin: 0;
}

.mobileHeroDescription {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

.mobileHeroButton {
  background-color: white;
  color: #4285F4;
  border: none;
  border-radius: 30px;
  padding: 15px 40px;
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  margin-top: 10px;
  width: 80%;
  max-width: 280px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4), 0 0 25px rgba(66, 133, 244, 0.7); /* 그림자 효과 더 진하게 수정 */
}


.mobileHeroImage {
  position: relative; /* 상대 위치 설정 */
  left: 100px; /* 오른쪽으로 이동 */
  width: 100%; /* 이미지가 섹션의 너비에 맞게 조정 */
  max-width: 300px; /* 최대 너비 설정 */
  height: auto; /* 비율 유지 */
  margin-top: 20px; /* 위쪽 여백 추가 */
}

.textExpand {
  display: inline-block;
  opacity: 0;
  transform: scale(0.5);
  white-space: nowrap;
  overflow: hidden;
}

.visible {
  animation: textExpand 0.8s ease-out forwards;
}

@keyframes textExpand {
  0% {
    transform: scale(0.5);
    letter-spacing: -8px;
    opacity: 0;
  }
  60% {
    letter-spacing: 4px;
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    letter-spacing: normal;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .ctaText {
    font-size: 18px;
    padding: 0 20px;
    word-break: keep-all;
  }
  
  .textExpand {
    white-space: normal;
    text-align: center;
  }
}

.cursor {
  display: inline-block;
  width: 2px;
  animation: blink 1s infinite;
  margin-left: 2px;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.exampleText {
  white-space: pre-wrap;
  line-height: 1.8;
  color: #666;
  font-size: 16px;
  margin: 0;
  text-align: left;
  transition: all 0.3s ease;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
  border-left: 4px solid #4285F4;
}