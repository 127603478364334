.App {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex: 1;
  justify-content: center;
}

.page-content {
  flex: 1;
  margin-left: 250px;
  transition: all 0.3s ease;
  min-height: 100vh;
  background-color: #ffffff;
  width: calc(100% - 250px);
  padding: 0 20px;
  box-sizing: border-box;
}

.page-content.content-closed {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.sidebar {
  transition: width 0.3s ease;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .page-content {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 60px;
    padding: 0 15px;
  }

  .content-wrapper {
    padding: 0 15px;
    width: 100%;
  }

  .page-content.content-closed {
    margin-left: 0 !important;
    max-width: none;
  }

  .sidebar {
    display: none;
  }
}
