.step-progress-container {
  padding: 32px 24px;
  max-width: 800px;
  margin: 0 auto;
}

.step-progress-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-item {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.step-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #e4ebff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 8px rgba(46, 116, 255, 0.08);
}

.step-item.active .step-circle {
  background: linear-gradient(135deg, #2E74FF, #4c8dff);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

.step-item.completed .step-circle {
  background: #2ECC71;
  border-color: transparent;
}

.step-number {
  font-size: 16px;
  font-weight: 600;
  color: #94a3b8;
  transition: all 0.3s ease;
}

.step-item.active .step-number {
  color: white;
}

.check-icon {
  width: 20px;
  height: 20px;
  fill: white;
}

.step-info {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.step-label {
  font-size: 15px;
  font-weight: 600;
  color: #1e293b;
  transition: all 0.3s ease;
}

.step-status {
  font-size: 13px;
  color: #94a3b8;
}

.step-item.active .step-label {
  color: #2E74FF;
}

.step-item.completed .step-label {
  color: #2ECC71;
}

.step-item.active .step-status {
  color: #2E74FF;
}

.step-item.completed .step-status {
  color: #2ECC71;
}

.step-connector {
  position: absolute;
  left: 62px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background: #e4ebff;
  z-index: 1;
}

.step-item.active .step-connector,
.step-item.completed .step-connector {
  background: linear-gradient(90deg, #2E74FF, #4c8dff);
}

@media (max-width: 768px) {
  .step-progress-container {
    padding: 24px 16px;
  }

  .step-circle {
    width: 36px;
    height: 36px;
  }

  .step-number {
    font-size: 14px;
  }

  .step-label {
    font-size: 13px;
  }

  .step-status {
    font-size: 11px;
  }

  .step-connector {
    left: 48px;
  }

  .check-icon {
    width: 16px;
    height: 16px;
  }
} 