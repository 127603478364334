.main-page {
  background-color: #ffffff;
  min-height: 100vh; /* 최소 높이를 화면 높이로 설정 */
  width: 100%; /* 너비를 100%로 설정 */
  padding: 80px 40px 160px;
  box-sizing: border-box; /* padding이 width에 포함되도록 설정 */
}

.main-title {
  text-align: center;
  margin-top: 100px; /* 100px에서 50px로 줄임 */
  margin-bottom: 40px;
  font-size: 32px;
  color: #333;
  font-weight: bold;
  white-space: nowrap; /* 줄바꿈 방지 */
}

.title-part1 {
  display: inline-block;
  animation: slideInRight 1s ease-out;
}

.title-part2 {
  display: inline-block; /* inline-block으로 변경 */
  animation: slideInUp 1s ease-out 0.8s;
  animation-fill-mode: both;
}

.tools-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* 최소 너비 증가 */
  gap: 20px; /* 그리드 아이템 간격 증가 */
  padding: 30px; /* 컨테이너 내부 여백 증가 */
  margin: 20px 0; /* 상하 여백 추가 */
}

.tool-item {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px; /* 아이템 사이 세로 간격을 5px로 줄임 */
}

.tool-icon {
  position: relative; /* 추가 */
  background-color: #e6f2ff;
  border-radius: 8px;
  padding: 12px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

.tool-icon:hover {
  background-color: #cce5ff; /* 호버 시 약간 더 진한 파란색 */
}

.tool-item img {
  width: 40px;
  height: 40px;
}

.tool-item p {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-top: 8px;
  text-align: center;
}

.info-container {
  display: flex;
  justify-content: space-between;
  gap: 30px; /* 박스 간격 증가 */
  margin-top: 60px; /* 상단 여백 증가 */
  padding: 0 20px; /* 좌우 여백 추가 */
}

.info-box {
  flex: 1;
  padding: 20px;
  padding-top: 10px; /* 상단 패딩을 10px로 줄임 */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.info-box h3 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.info-box ol, .info-box div {
  padding-left: 0;
  list-style-type: none;
  counter-reset: item;
}

.info-box li, .info-box p {
  margin: 10px 0;
  padding: 15px;
  font-size: 14px;
  color: #333;
  border-radius: 5px;
  position: relative;
  padding-left: 50px;
  display: flex;
  align-items: center;
}

.info-box li::before, .info-box p::before {
  position: absolute;
  left: 15px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* 뉴스 섹션 스타일 */
.news {
  background-color: #e8f4ea;
}

.news h3 {
  color: #1a5d1a;
}

.news li {
  background-color: #ffffff;
}

.news li::before {
  content: counter(item);
  counter-increment: item;
  background-color: #b6e3b6;
  color: #1a5d1a;
}

/* 커뮤니티 섹션 스타일 */
.community {
  background-color: #fff0f5;
}

.community h3 {
  color: #d81b60;
}

.community ol {
  padding-left: 0;
  list-style-type: none;
  counter-reset: item;
}

.community li {
  margin: 10px 0;
  padding: 15px;
  font-size: 14px;
  color: #333;
  border-radius: 5px;
  position: relative;
  padding-left: 50px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.community li::before {
  content: counter(item);
  counter-increment: item;
  position: absolute;
  left: 15px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #ffcce0;
  color: #d81b60;
}

.community li a {
  text-decoration: none;
  color: #333;
}

.community li a:hover {
  text-decoration: underline;
}

/* 채용공고 섹션 스타일 */
.job-posting {
  background-color: #fff8e8;
}

.job-posting h3 {
  color: #f57c00;
}

.job-posting p {
  background-color: #ffffff;
}

.job-posting p::before {
  content: "•";
  background-color: #ffe0b2;
  color: #f57c00;
}

.job-posting p:first-of-type {
  font-weight: bold;
  color: #333;
}

/* 공통 스타일 */
.info-box .post-title,
.info-box .news-title,
.info-box .job-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box .post-stats,
.info-box .news-date,
.info-box .job-company {
  font-size: 0.8em;
  color: #666;
  margin-left: 10px;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 새로운 스타일 추가 */
.tool-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 6px;
  border-radius: 10px;
  z-index: 1;
  animation: pulse 1.5s infinite;
}

.tool-badge.hot {
  background-color: #ff4757;
}

.tool-badge.popular {
  background-color: #ffa502;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.info-box.community ol {
  padding-left: 20px;
}

.info-box.community li {
  margin-bottom: 10px;
}

.info-box.community a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.info-box.community .post-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px; 
}

.info-box.community .post-stats {
  display: flex;
  gap: 10px;
}

.info-box.community .likes,
.info-box.community .views {
  font-size: 1.2em; /* 0.8em에서 1em으로 크기 증가 */
  color: #666;
}

/* 모바일 스타일 추가 */
@media (max-width: 768px) {
  .main-page {
    padding: 80px 15px 60px; /* 상단 패딩 수정 */
    width: 100%;
    margin: 0;
  }
  
  .main-title {
    font-size: 24px;
    line-height: 1.4;
    margin: 10px 0; /* 상하 마진도 줄임 */
    padding: 0 15px;
    white-space: normal;
    animation: fadeInDown 0.8s ease-out;
  }
  
  .main-title span {
    display: inline-block;
    animation: fadeInUp 0.8s ease-out 0.4s;
    animation-fill-mode: both;
  }

  .tools-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 0;
    margin: 15px 0;
  }

  .tool-icon {
    width: 52px;
    height: 52px;
    padding: 10px;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    border: 1.5px solid #e8edf3;
    transition: all 0.2s ease;
  }

  .tool-icon:active {
    transform: scale(0.95);
    background-color: #f8f9fa;
  }

  .tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 16px;
    transition: transform 0.2s ease;
  }

  .tool-item img {
    width: 32px;
    height: 32px;
    transition: transform 0.2s ease;
  }

  .tool-item p {
    font-size: 12px;
    margin-top: 8px;
    font-weight: 600;
    color: #2c3e50;
  }

  .tool-badge {
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 10px;
    padding: 3px 6px;
    border-radius: 10px;
    background-color: #2E74FF;
    color: white;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(46, 116, 255, 0.3);
  }

  .info-container {
    flex-direction: column;
    gap: 15px;
    margin-top: 30px;
    padding: 0;
  }

  .info-box {
    padding: 15px;
    margin: 0;
  }

  .info-box h3 {
    font-size: 16px;
    margin: 0 0 10px 0;
    padding-left: 8px;
    border-left: 3px solid #2E74FF;
  }

  .info-box.community .post-title {
    font-size: 14px;
  }

  .info-box.job-posting p {
    margin: 5px 0;
    font-size: 14px;
  }
}

/* 애니메이션 키프레임 추가 */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

