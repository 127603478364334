.payment-fail-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .fail-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .fail-header h2 {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .fail-header p {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }
  
  .error-details-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    text-align: left;
  }
  
  .error-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
  }
  
  .error-message {
    color: #FF0000;
    font-weight: bold;
  }
  
  .payment-id {
    color: #0070f3;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .retry-button, .main-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .retry-button {
    background-color: #333;
    color: #fff;
  }
  
  .main-button {
    background-color: #e0e0e0;
    color: #333;
  }