/* Overall container for resume generator */
.resume-generator {
  max-width: 1200px;
  padding: 48px;
  background-color: #fafbfc;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}

/* Header styles */
.resume-generator h2 {
  font-size: 32px;
  color: #1a1f36;
  margin-bottom: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.resume-generator p {
  font-size: 17px;
  line-height: 1.6;
  color: #4f566b;
  margin-bottom: 30px;
}

/* Flex container for horizontal alignment */
.field-group {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

/* Adjusting the size and padding of each field */
.field-group label,
.experience-section label,
.keyword-section label {
  flex: 1;
}

.field-group input {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.15);
  outline: none;
}

/* Specific styles for 경력 기술 input and button */
.experience-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-group .experience-section input {
  width: 30% !important; /* 특정성을 높이고 우선순위를 강제함 */
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.experience-section button {
  margin-left: 10px;
  padding: 14px 24px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  transition: all 0.2s ease;
  background-color: #2E74FF;
  color: white;
  border: none;
  cursor: pointer;
}

.experience-section button:hover {
  background-color: #1b64ff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

/* Specific styles for 키워드 input and button */
.keyword-section {
  display: column;
  align-items: center;
  margin-bottom: 10px;
}

.form-group .keyword-section input {
  width: 30% !important; /* 특정성을 높이고 우선순위를 강제함 */
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.keyword-section button {
  margin-left: 10px;
  padding: 14px 24px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  transition: all 0.2s ease;
  background-color: #2E74FF;
  color: white;
  border: none;
  cursor: pointer;
}

.keyword-section button:hover {
  background-color: #1b64ff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

.experience-list,
.keyword-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

/* Tags for experience and keywords */
.tag {
  background-color: #edf2ff;
  border: none;
  color: #2E74FF;
  padding: 8px 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(46, 116, 255, 0.1);
}

.tag button {
  color: #2E74FF;
  margin-left: 8px;
  font-size: 16px;
}

/* Action buttons container */
.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Button styling */
.action-buttons button {
  padding: 14px 24px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  transition: all 0.2s ease;
  background-color: #2E74FF;
  color: white;
  border: none;
  cursor: pointer;
}

.action-buttons button:first-child {
  background-color: #e9ecef;
  color: #adb5bd;
}

.action-buttons button:first-child:not([disabled]) {
  background-color: #2E74FF;
  color: white;
  cursor: pointer;
}

.action-buttons button:first-child:not([disabled]):hover {
  background-color: #1b64ff;
}

.action-buttons button:last-child {
  background-color: white;
  color: #2E74FF;
  border: 1px solid #2E74FF;
}

.action-buttons button:last-child:hover {
  background-color: #f8f9fa;
}

/* 모달 스타일 */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1000;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  width: 600px;
  max-width: 90%;
}

.modal-content {
  padding: 32px;
}

.modal-content h3 {
  font-size: 24px;
  color: #1a1f36;
  margin-bottom: 24px;
}

.modal-content button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* 버튼 너비 조정 */
}

.modal-content button:hover {
  background-color: #0056b3;
}

.action-buttons button:hover {
  background-color: #0056b3;
}

.category-selector {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.category-list {
  width: 200px;
  border-right: 1px solid #e0e0e0;
}

.category-item {
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 4px 0;
}

.category-item:hover {
  background-color: #f5f5f5;
}

.category-item.active {
  background-color: #2E74FF;
  color: white;
}

.job-list {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 0 16px;
}

.job-item {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.job-item:hover {
  border-color: #2E74FF;
  box-shadow: 0 2px 8px rgba(46, 116, 255, 0.1);
}

.job-item.active {
  border-color: #2E74FF;
  background-color: #f0f7ff;
}

.job-tasks {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.task-item {
  font-size: 12px;
  color: #666;
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
}

.job-selector-container {
  width: 100%;
  margin: 20px 0;
}

.job-selector-container > label {
  width: 100%;
}

.job-category-container {
  margin-top: 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  background: white;
}

.category-selector-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-height: 300px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 24px;
}

.category-column {
  flex: 0 0 250px;
  border-right: 1px solid #eee;
  padding-right: 15px;
}

.category-column:last-child {
  border-right: none;
}

.category-column h4 {
  margin: 0 0 12px 0;
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-height: 250px;
  overflow-y: auto;
}

.category-item {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 13px;
  color: #666;
  background: #f8f9fa;
}

.category-item:hover {
  background: #f0f7ff;
  color: #2E74FF;
}

.category-item.active {
  background: #2E74FF;
  color: white;
}

.selected-job-info {
  margin-top: 16px;
  padding: 24px;
  background: #f8faff;
  border-radius: 12px;
  border: 1px solid #e4ebff;
}

.job-info-content {
  display: flex;
  align-items: center;
  gap: 32px;
}

.job-info-item {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}

.job-info-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -16px;
  height: 24px;
  width: 1px;
  background-color: #e4ebff;
}

.job-label {
  font-size: 13px;
  font-weight: 600;
  color: #4a73e8;
  background-color: #edf2ff;
  padding: 4px 8px;
  border-radius: 4px;
}

.job-value {
  font-size: 14px;
  font-weight: 500;
  color: #2E74FF;
  letter-spacing: -0.3px;
}

.job-tasks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

.task-tag {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #edf2ff;
  border: 1px solid #e4ebff;
  border-radius: 16px;
  gap: 6px;
  font-size: 13px;
  color: #4a73e8;
  font-weight: 500;
}

.task-tag button {
  padding: 0;
  background: none;
  border: none;
  color: #4a73e8;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s ease;
}

.task-tag button:hover {
  color: #2E74FF;
}

/* 경력 기술과 키워드 컨테이너 */
.experience-keyword-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;
  width: 100%;
}

/* 경력 기술과 키워드 섹션 공통 스타일 */
.experience-section,
.keyword-section {
  position: relative;
  width: 100%;
}

.experience-section label,
.keyword-section label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  font-weight: 500;
  color: #1a1f36;
}

.tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f0f7ff;
  color: #2E74FF;
  font-size: 14px;
  cursor: help;
  margin-left: 8px;
  transition: all 0.2s ease;
}

.experience-section input,
.keyword-section input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.experience-section input:focus,
.keyword-section input:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.experience-section button,
.keyword-section button {
  padding: 12px 20px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.experience-section button:hover,
.keyword-section button:hover {
  background-color: #1b64ff;
  transform: translateY(-1px);
}

.experience-list,
.keyword-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #f0f7ff;
  border-radius: 6px;
  font-size: 14px;
  color: #2E74FF;
  font-weight: 500;
}

.tag button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background: none;
  border: none;
  color: #2E74FF;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.tag button:hover {
  opacity: 1;
}

/* 툴팁 커스텀 스타일 */
:root {
  --rt-color-white: #fff;
  --rt-color-dark: #1a1f36;
  --rt-color-success: #2E74FF;
  --rt-opacity: 0.95;
}

/* 입력 드 공통 스타일 */
.form-group input {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  transition: all 0.3s ease;
}

/* 제목과 기업명 섹션 */
.title-company-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
}

.input-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.input-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.input-header label {
  font-size: 16px;
  font-weight: 600;
  color: #1a1f36;
}

.styled-input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 16px;
  color: #2E74FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styled-input-group input {
  width: 100%;
  padding: 14px 16px 14px 48px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 15px;
  background-color: #f8f9fc;
  transition: all 0.2s ease;
}

.styled-input-group input:focus {
  border-color: #2E74FF;
  background-color: white;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  outline: none;
}

.styled-input-group input::placeholder {
  color: #a0a6b6;
}

/* 툴팁 아이콘 스타일 업데이트 */
.tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e4ebff;
  color: #2E74FF;
  font-size: 12px;
  cursor: help;
  transition: all 0.2s ease;
}

.tooltip-icon:hover {
  background-color: #2E74FF;
  color: white;
  transform: translateY(-1px);
}

/* 툴팁 스타일 커스터마이징 */
:root {
  --rt-color-white: #fff;
  --rt-color-dark: #1a1f36;
  --rt-opacity: 0.95;
}

.react-tooltip {
  font-size: 13px !important;
  line-height: 1.6 !important;
  max-width: 300px !important;
  white-space: pre-line !important;
  padding: 12px 16px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  z-index: 1000 !important;
}

[data-tooltip] {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
}

/* 경력 기술과 키워드 컨테이너 */
.experience-keyword-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;
  width: 100%;
}

/* 카드 스타일 공통 */
.card-section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
}

/* 경력 기술과 키워드 섹션 */
.experience-section,
.keyword-section {
  composes: card-section;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* 입력 그룹 스타일 */
.input-group {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.input-group input {
  flex: 1;
  min-width: 0;
}

.input-group button {
  flex-shrink: 0;
  padding: 14px 24px;
  white-space: nowrap;
}

/* 섹션 헤더 스타일 */
.section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  width: 100%;
}

.section-header label {
  font-weight: 600;
  color: #1a1f36;
  font-size: 16px;
}

/* 태그 리트 스타일 */
.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  width: 100%;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f0f7ff;
  border-radius: 8px;
  font-size: 14px;
  color: #2E74FF;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tag button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: none;
  border: none;
  color: #2E74FF;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.tag button:hover {
  opacity: 1;
}

/* 툴팁 아이콘 스타일 */
.tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e4ebff;
  color: #2E74FF;
  font-size: 12px;
  cursor: help;
  transition: all 0.2s ease;
}

.tooltip-icon:hover {
  background-color: #2E74FF;
  color: white;
}

/* 경력 기술과 키워드 전체 래퍼 */
.experience-keyword-wrapper {
  width: 100%;
  margin: 24px 0;
}

/* 섹션 컨테이너 */
.section-container {
  display: flex;
  gap: 24px;
  width: 100%;
}

.card-section {
  flex: 1;  /* 각 섹션이 동일한 너비를 가지도록 설정 */
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* 입력 그룹 스타일 수정 */
.input-group {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 16px;
}

.input-group input {
  flex: 1;  /* 입력 필드가 남은 공간을 모두 차지하도록 설정 */
  min-width: 0;  /* flex 아이템의 최소 너비 설정 */
}

.input-group button {
  white-space: nowrap;  /* 버 텍스트 줄바꿈 방지 */
}

/* 태그 리스트 스타일 수정 */
.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #f5f7ff;
  border-radius: 6px;
  font-size: 14px;
}

.tag button {
  padding: 0;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 섹션 헤더 스타일 */
.section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.section-header label {
  font-weight: 600;
  color: #333;
}

/* 진행 상태 표시바 추가 */
.progress-bar-container {
  margin: 0 auto 40px;
  max-width: 400px;
  padding: 0 20px;

}

.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.progress-bar::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background: #e4ebff;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  border-radius: 4px;
}

.progress-bar::after {
  content: '';
  position: absolute;
  left: 0;
  width: 50%;  /* 1번 스텝까지만 색상 적용 */
  height: 3px;
  background: linear-gradient(90deg, #2E74FF, #4c8dff);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 4px;
}

.progress-step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 3px solid #e4ebff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #a0a6b6;
  position: relative;
  z-index: 2;
}

/* 1번 스텝만 활성화 */
.progress-step:first-child {
  background: linear-gradient(135deg, #2E74FF, #4c8dff);
  border-color: #2E74FF;
  color: white;
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

/* 섹션 구분선 추가 */
.section-divider {
  margin: 40px 0;
  height: 1px;
  background: linear-gradient(to right, #e4ebff, #f8f9fc, #e4ebff);
}

/* 입력 필드 포커스 효과 개선 */
.styled-input-group input:focus {
  border-color: #2E74FF;
  background-color: white;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  transform: translateY(-1px);
}

/* 태그 디자인 개선 */
.tag {
  padding: 8px 16px;
  background: linear-gradient(135deg, #f0f7ff, #e4ebff);
  border: 1px solid #e4ebff;
  box-shadow: 0 2px 4px rgba(46, 116, 255, 0.05);
}

/* 버튼 스타일 개선 */
.action-buttons {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 20px 0;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.05);
  margin: 0 -48px;
  padding: 20px 48px;
}

.action-buttons button {
  min-width: 120px;
  height: 48px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.action-buttons button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

/* 섹션 제목 스타일 강화 */
.input-header label,
.form-group > label,
.section-header label {
  font-size: 20px;  /* 폰트 크기 증가 */
  font-weight: 600;
  color: #1a1f36;
  letter-spacing: -0.5px;
}

/* 아이콘 위치 조정 */
.input-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #2E74FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 입력 필드 패딩 정 (아이콘 공간 확보) */
.styled-input-group input,
.input-group input {
  padding-left: 48px;  /* 아이콘을 위한 여백 */
}

/* 툴팁 아이콘 스타일 개선 */
.tooltip-icon {
  width: 24px;  /* 크기 증가 */
  height: 24px;
  margin-left: 8px;
  background-color: #f0f7ff;
}

/* 섹션 헤더 스타일 수정 */
.section-header,
.input-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* 섹션 제목 레이블 컨테이너 */
.section-header label,
.input-header label {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #1a1f36;
  margin-right: 8px;
}

/* 아이콘 스타일 */
.section-icon {
  color: #2E74FF;  /* 파란색 아이콘 */
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

/* 툴팁 아이콘 스타일 */
.tooltip-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f0f7ff;
  color: #2E74FF;
  font-size: 14px;
  cursor: help;
  margin-left: 8px;
  transition: all 0.2s ease;
}

.tooltip-icon:hover {
  background-color: #2E74FF;
  color: white;
}

/* 섹션 간격 조정 */
.form-group > div:not(:last-child) {
  margin-bottom: 32px;
}

/* 입력 필드 관련 스타일 수정 */
.styled-input-group,
.input-group {
  position: relative;
  width: 100%;
}

.styled-input-group input,
.input-group input {
  width: 100%;
  padding: 12px 16px;  /* 좌측 패딩 원복 */
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s ease;
}

/* 기존 input-icon 관련 스타일 제거 */
.input-icon {
  display: none;
}

/* 직무 선택 컨테이너 스타일 */
.job-category-container {
  width: 100%;
  background: #fff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
}

/* JobCategorySelector 컴포넌트 스타일 */
.job-category-selector {
  width: 100%;
  min-height: 48px;
}

/* 선택된 직무 정보 스타일 */
.selected-job-info {
  margin-top: 16px;
  padding: 24px;
  background: #f8faff;
  border-radius: 12px;
  border: 1px solid #e4ebff;
}

.job-info-content {
  display: flex;
  align-items: center;
  gap: 32px;
}

.job-info-item {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}

.job-info-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -16px;
  height: 24px;
  width: 1px;
  background-color: #e4ebff;
}

.job-label {
  font-size: 13px;
  font-weight: 600;
  color: #4a73e8;
  background-color: #edf2ff;
  padding: 4px 8px;
  border-radius: 4px;
}

.job-value {
  font-size: 14px;
  font-weight: 500;
  color: #2E74FF;
  letter-spacing: -0.3px;
}

.job-tasks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

.task-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #fff;
  border: 1px solid #e4ebff;
  border-radius: 6px;
  font-size: 14px;
  color: #2E74FF;
}

.task-tag button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  color: currentColor;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.task-tag button:hover {
  opacity: 1;
}

/* 페이지 전체 컨테이너 개선 */
.resume-generator-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 24px;
  background-color: #f8faff;
}

/* 헤더 섹션 스타일 개선 */
.page-header {
  text-align: center;
  margin-bottom: 48px;
  padding: 40px;
  background: linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(46, 116, 255, 0.08);
  border: 1px solid rgba(46, 116, 255, 0.1);
}

.page-header h1 {
  font-size: 32px;
  color: #1a1f36;
  margin-bottom: 16px;
  font-weight: 700;
}

.page-header p {
  font-size: 16px;
  color: #4a5568;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

/* 입력 카드 스타일 개선 */
.input-card {
  background: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #e4e7ec;
  margin-bottom: 24px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.input-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #2E74FF;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.input-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(46, 116, 255, 0.12);
}

.input-card:hover::before {
  opacity: 1;
}

/* 입력 필드 스타일 개선 */
.styled-input-group input {
  height: 56px;
  font-size: 16px;
  padding: 0 20px;
  border: 2px solid #e4e7ec;
  background-color: #f8faff;
  width: 100%;
  transition: all 0.2s ease;
}

.styled-input-group input:focus {
  border-color: #2E74FF;
  background-color: white;
  box-shadow: 0 0 0 4px rgba(46, 116, 255, 0.1);
}

.styled-input-group input::placeholder {
  color: #a0aec0;
}

/* 진행 상태 표시 개선 */
.progress-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  gap: 16px;
}

.progress-step {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f0f7ff;
  border-radius: 20px;
  font-size: 14px;
  color: #2E74FF;
  font-weight: 600;
}

.progress-step.active {
  background: #2E74FF;
  color: white;
}

.progress-step span {
  background: rgba(255, 255, 255, 0.2);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

/* 모바일 폰트 사이즈 최적화 */
@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }

  .job-info-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .job-info-item:not(:last-child)::after {
    display: none;
  }

  .job-label {
    font-size: 12px;
  }

  .job-value {
    font-size: 13px;
  }

  .task-tag {
    font-size: 13px;
    padding: 6px 10px;
  }

  .job-category-container {
    overflow-y: auto; /* 스크롤 추가 */
    max-height: 300px; /* 최대 높이 설정 */
  }

  .job-category-container .job-item {
    width: 100%; /* 모바일에서 전체 너비 사용 */
  }

  /* 경력 기술 섹션 모바일 최적화 */
  .experience-section {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .form-group .experience-section input {
    width: 100% !important;
    font-size: 14px;
    padding: 10px;
  }

  .experience-section button {
    width: 100%;
    padding: 12px;
    font-size: 14px;
  }

  /* 태그 리스트 모바일 최적화 */
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
  }

  .tag {
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: #f0f7ff;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .tag button {
    padding: 2px 6px;
    font-size: 12px;
    background: none;
    border: none;
    color: #666;
  }

  /* 키워드 입력 섹션 모바일 최적화 */
  .keyword-section {
    margin-bottom: 16px;
  }

  .input-group {
    flex-direction: column;
    gap: 8px;
  }

  .input-group input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
  }

  .input-group button {
    width: 100%;
    padding: 12px;
    font-size: 14px;
  }

  /* 섹션 헤더 모바일 최적화 */
  .section-header {
    margin-bottom: 12px;
  }

  .section-header label {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  /* 툴팁 아이콘 모바일 최적화 */
  .tooltip-icon {
    font-size: 14px;
    padding: 2px 6px;
  }

  /* 카드 섹션 모바일 패딩 조정 */
  .card-section {
    padding: 16px;
    margin-bottom: 16px;
  }

  /* 경력 기술과 키워드 컨테이너 모바일 최적화 */
  .experience-keyword-container {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .experience-section,
  .keyword-section {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid #e4e7ec;
  }

  .section-header {
    margin-bottom: 16px;
  }

  .section-header label {
    font-size: 16px;
    color: #1a1f36;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .input-group input {
    width: 100%;
    height: 44px;
    padding: 0 16px;
    font-size: 14px;
    border: 1.5px solid #e4e7ec;
    border-radius: 8px;
    background-color: #f8faff;
  }

  .input-group input:focus {
    border-color: #2E74FF;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }

  .input-group button {
    width: 100%;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    background: #2E74FF;
    color: white;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .input-group button:active {
    transform: translateY(1px);
  }

  /* 태그 리스트 개선 */
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
    padding: 12px;
    background: #f8faff;
    border-radius: 8px;
    border: 1px dashed #e4e7ec;
  }

  .tag {
    background: #ffffff;
    border: 1px solid #e4ebff;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 13px;
    color: #2E74FF;
    display: flex;
    align-items: center;
    gap: 6px;
    box-shadow: 0 2px 4px rgba(46, 116, 255, 0.05);
  }

  .tag button {
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f7ff;
    border: none;
    border-radius: 4px;
    color: #2E74FF;
    font-size: 14px;
  }

  /* 툴팁 아이콘 개선 */
  .tooltip-icon {
    width: 20px;
    height: 20px;
    background: #f0f7ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #2E74FF;
    margin-left: 8px;
  }

  /* 빈 상태 메시지 */
  .tag-list:empty::before {
    content: '입력된 항목이 없습니다';
    color: #a0aec0;
    font-size: 13px;
    width: 100%;
    text-align: center;
    padding: 12px;
  }
}

/* 경력사항 섹션 스타일링 */
.career-selector {
  background: #fff;
  border-radius: 16px;
  padding: 28px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  margin: 24px 0;
}

.career-selector h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1a1f36;
  margin-bottom: 8px;
}

.career-subtitle {
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 24px;
}

.filter-button-group {
  display: flex;
  gap: 12px;
}

.filter-button-group button {
  flex: 1;
  padding: 16px 24px;
  border-radius: 12px;
  border: 2px solid #e5e7eb;
  background: #fff;
  color: #4b5563;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.filter-button-group button:hover {
  border-color: #2E74FF;
  background: #f8faff;
  transform: translateY(-1px);
}

/* 선택된 버튼 스타일 */
.filter-button-group button.selected {
  background: #2E74FF;
  color: #fff;
  border-color: #2E74FF;
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

.filter-button-group button.selected::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

/* 버튼 아이콘 추가 */
.filter-button-group button::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.filter-button-group button:first-child::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M20 12H4M20 12a2 2 0 002-2V6a2 2 0 00-2-2H4a2 2 0 00-2 2v4a2 2 0 002 2M20 12v4a2 2 0 01-2 2H6a2 2 0 01-2-2v-4'/%3E%3C/svg%3E");
}

.filter-button-group button:last-child::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'/%3E%3C/svg%3E");
}