.resume-builder-view {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.view-header h2 {
  font-size: 24px;
  color: #333;
}

.view-actions {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.view-actions button {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
}

.view-actions .export-button {
  background: white;
  color: #2E74FF;
  border: 2px solid #2E74FF;
}

.view-actions .print-button {
  background: linear-gradient(135deg, #2E74FF 0%, #4c8dff 100%);
  color: white;
  border: none;
}

.view-actions button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

@media print {
  .view-actions {
    display: none;
  }
}

.view-content {
  background-color: white;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
} 