.resume-template {
  padding: 40px;
  background: #fff;
  min-height: 100vh;
}

.resume-container {
  max-width: 210mm;
  margin: 0 auto;
  padding: 50px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.resume-title {
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 2px solid #333;
}

.resume-title h1 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 10px;
  margin-bottom: 10px;
}

.info-table,
.content-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.info-table th {
  background: #f8f9fa;
  width: 120px;
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
  font-weight: 600;
}

.info-table td {
  padding: 15px;
  border: 1px solid #ddd;
}

.photo-cell {
  width: 130px;
  vertical-align: middle;
  text-align: center;
}

.profile-photo {
  width: 120px;
  height: 160px;
  object-fit: cover;
}

.photo-placeholder {
  width: 120px;
  height: 160px;
  line-height: 160px;
  background: #f8f9fa;
  border: 1px dashed #ddd;
}

.section {
  margin-top: 40px;
}

.section h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #333;
}

.content-table th {
  background: #f8f9fa;
  padding: 12px;
  border: 1px solid #ddd;
  font-weight: 600;
}

.content-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  
  body * {
    visibility: hidden;
  }
  
  .resume-template,
  .resume-template * {
    visibility: visible;
  }
  
  .resume-template {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .resume-container {
    padding: 40px !important;
    box-shadow: none !important;
  }

  .info-table,
  .content-table {
    border-color: #000 !important;
  }

  .info-table th,
  .content-table th {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    color-adjust: exact;
  }
  
  .view-header,
  .view-actions,
  .preview-header,
  .preview-actions {
    display: none !important;
  }
}

/* 모던 템플릿 스타일 강화 */
.resume-template.template-modern {
  background: #fff;
}

.resume-template.template-modern .resume-container {
  padding: 60px;
  background: linear-gradient(to bottom right, #ffffff, #f8f9ff);
  border-radius: 20px;
  box-shadow: 
    0 20px 40px rgba(0,0,0,0.05),
    0 1px 3px rgba(0,0,0,0.05);
}

.resume-template.template-modern .resume-title {
  border: none;
  position: relative;
  padding-bottom: 30px;
}

.resume-template.template-modern .resume-title h1 {
  font-size: 42px;
  background: linear-gradient(45deg, #2E74FF, #87CEFA);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 8px;
}

.resume-template.template-modern .resume-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #2E74FF, #87CEFA);
}

.resume-template.template-modern .info-table {
  border: none !important;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  margin: 40px 0;
}

.resume-template.template-modern .info-table th {
  background: #2E74FF !important;
  color: white !important;
  font-weight: 500;
  padding: 15px 20px;
  border: none !important;
}

.resume-template.template-modern .info-table td {
  padding: 15px 20px;
  border: 1px solid #eef1ff !important;
  background: white;
}

.resume-template.template-modern .photo-cell {
  background: white;
  border: none !important;
  padding: 15px !important;
}

.resume-template.template-modern .profile-photo {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.resume-template.template-modern .section {
  margin-top: 60px;
}

.resume-template.template-modern .section h2 {
  font-size: 24px;
  color: #2E74FF;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border: none;
  position: relative;
}

.resume-template.template-modern .section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2E74FF, #87CEFA);
}

.resume-template.template-modern .content-table {
  border: none !important;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  margin: 20px 0;
}

.resume-template.template-modern .content-table th {
  background: #f0f4ff !important;
  color: #2E74FF !important;
  font-weight: 500;
  padding: 15px 20px;
  border: none !important;
}

.resume-template.template-modern .content-table td {
  padding: 15px 20px;
  border: 1px solid #eef1ff !important;
}

.resume-template.template-modern .content-table tr:hover {
  background-color: #f8f9ff;
}

/* 클래식 템플릿 스타일 */
.resume-template.template-classic .resume-container {
  padding: 50px;
  border: 3px double #000;
  background: #fff;
  position: relative;
}

.resume-template.template-classic .resume-title {
  border-bottom: 3px solid #000;
  margin-bottom: 40px;
}

.resume-template.template-classic .resume-title h1 {
  font-size: 38px;
  font-family: 'Noto Serif KR', serif;
  letter-spacing: 12px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}

.resume-template.template-classic .info-table,
.resume-template.template-classic .content-table {
  border: 2px solid #000;
}

.resume-template.template-classic .info-table th,
.resume-template.template-classic .content-table th {
  background: #f0f0f0;
  border: 1.5px solid #000;
  padding: 15px;
}

.resume-template.template-classic .info-table td,
.resume-template.template-classic .content-table td {
  border: 1.5px solid #000;
  padding: 15px;
}

.resume-template.template-classic .section h2 {
  font-size: 24px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
  letter-spacing: 4px;
}

/* 심플 템플릿 스타일 */
.resume-template.template-simple .resume-container {
  padding: 60px;
  background: #fff;
  border: none;
  box-shadow: none;
}

.resume-template.template-simple .resume-title {
  border: none;
  margin-bottom: 50px;
}

.resume-template.template-simple .resume-title h1 {
  font-size: 32px;
  color: #333;
  letter-spacing: 4px;
  font-weight: 500;
}

.resume-template.template-simple .info-table,
.resume-template.template-simple .content-table {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.resume-template.template-simple .info-table th,
.resume-template.template-simple .content-table th {
  border: none;
  border-bottom: 2px solid #eee;
  padding: 15px;
  color: #555;
  font-weight: 500;
}

.resume-template.template-simple .info-table td,
.resume-template.template-simple .content-table td {
  border: none;
  border-bottom: 1px solid #eee;
  padding: 15px;
}

.resume-template.template-simple .section h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/* 프린트 스타일 유지 */
@media print {
  .resume-template.template-modern .resume-container,
  .resume-template.template-modern .info-table,
  .resume-template.template-modern .content-table {
    box-shadow: none;
  }
  
  .resume-template.template-modern .info-table th,
  .resume-template.template-modern .content-table th,
  .resume-template.template-modern .info-table td,
  .resume-template.template-modern .content-table td {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/* 추가할 만한 스타일 */

/* 1. 다크 모드 지원 */
@media (prefers-color-scheme: dark) {
  .resume-template {
    /* 다크 모드 스타일 */
  }
}

/* 2. 모바일 최적화 */
@media screen and (max-width: 768px) {
  .resume-container {
    padding: 20px;
  }
  
  .info-table th {
    width: auto;
  }
}

/* 3. 애니메이션 효과 */
.resume-template.template-modern .section {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* 4. 접근성 개선 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/* 템플릿별 스타일 */
.resume-container.modern {
  padding: 60px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  border: none;
  border-radius: 16px;
}

.resume-container.classic {
  padding: 50px;
  border: 2px solid #000;
}

.resume-container.simple {
  padding: 50px;
  border: none;
  box-shadow: none;
}

/* 모던 템플릿 테이블 */
.modern .info-table,
.modern .content-table {
  border: none;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
}

/* 클래식 템플릿 테이블 */
.classic .info-table,
.classic .content-table {
  border: 2px solid #000;
}

/* 심플 템플릿 테이블 */
.simple .info-table,
.simple .content-table {
  border: none;
} 