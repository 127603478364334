.payment-success-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .success-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .success-header h2 {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .plan-summary-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .plan-info {
    text-align: left;
    margin-bottom: 10px;
  }
  
  .plan-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .plan-price {
    font-size: 16px;
    color: #333;
  }
  
  .plan-tokens {
    font-size: 14px;
    color: #555;
  }
  
  .features-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .features-list li svg {
    margin-right: 10px;
  }
  
  .payment-details {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .main-button, .tokens-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .main-button {
    background-color: #0070f3;
    color: #fff;
  }
  
  .tokens-button {
    background-color: #e0e0e0;
    color: #333;
  }