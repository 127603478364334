/* TechTreeGeneratorPage.module.css */
.techTreeContainer {
  max-width: 600px;
  margin: 48px auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(46, 116, 255, 0.08);
  border: 1px solid rgba(46, 116, 255, 0.1);
}

/* 기존 전역 스타일을 수정하여 특정 클래스로 구별 */
.heading {
  font-size: 28px;
  font-weight: 700;
  color: #191F28;
  text-align: center;
  margin-bottom: 12px;
  letter-spacing: -0.5px;
}

.subHeading {
  font-size: 15px;
  color: #8B95A1;
  text-align: center;
  margin-bottom: 40px;
}

.formGroup {
  max-width: 400px;
  margin: 0 auto 28px auto;
  position: relative;
  animation: slideIn 0.3s ease-out forwards;
}

.formGroup:nth-child(2) {
  animation-delay: 0.1s;
}

.formGroup:nth-child(3) {
  animation-delay: 0.2s;
}

.formGroup label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 8px;
}

.formGroup label::before {
  content: '•';
  color: #2E74FF;
  font-size: 1.5em;
}

.inputContainer {
  position: relative;
}

input[type="text"] {
  width: 100%;
  height: 56px;
  padding: 0 20px;
  font-size: 15px;
  border: 2px solid #e4e7ec;
  border-radius: 12px;
  background-color: #f8faff;
  transition: all 0.2s ease;
}

input[type="text"]:hover {
  border-color: #cbd5e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

input[type="text"]:focus {
  background-color: #FFFFFF;
  border-color: #2E74FF;
  box-shadow: 0 0 0 4px rgba(46, 116, 255, 0.1);
  transform: translateY(-1px);
  outline: none;
}

.charCount {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  color: #8B95A1;
}

.submitBtn {
  width: 100%;
  max-width: 400px;
  margin: 40px auto 0;
  display: block;
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(135deg, #3182F6, #2E74FF);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submitBtn:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

.submitBtn:disabled {
  background-color: #DFE3E8;
  cursor: not-allowed;
}

/* 결과 화면의 스타일 */
.techTreeResult {
  margin-top: 40px;
}

.recommendationSection {
  background: #FFFFFF;
  border: 1px solid #DFE3E8;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  transition: all 0.2s ease;
}

.recommendationSection h4 {
  font-size: 18px;
  font-weight: 600;
  color: #191F28;
  margin-bottom: 16px;
}

.recommendationSection ul {
  list-style: none;
  padding: 0;
}

.recommendationSection li {
  padding: 16px;
  background: #F9FAFB;
  border-radius: 12px;
  margin-bottom: 12px;
}

.recommendationSection li:last-child {
  margin-bottom: 0;
}

.buttonGroup {
  display: flex;
  gap: 12px;
  margin-top: 32px;
}

.copyBtn, .saveBtn {
  flex: 1;
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.copyBtn {
  background-color: #F9FAFB;
  border: 1px solid #DFE3E8;
  color: #4E5968;
}

.saveBtn {
  background-color: #3182F6;
  border: none;
  color: #FFFFFF;
}

/* ... 기존 스타일 ... */

.popup {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #191F28;
  color: #FFFFFF;
  padding: 16px 24px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  animation: slideUp 0.3s ease;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .techTreeContainer {
    margin: 16px;
    padding: 24px;
    border-radius: 16px;
  }

  .heading {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .subHeading {
    font-size: 14px;
    margin-bottom: 32px;
    padding: 0 16px;
  }

  .formGroup {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .formGroup label {
    font-size: 14px;
  }

  input[type="text"] {
    height: 52px;
    font-size: 14px;
    padding: 0 16px;
  }

  .charCount {
    font-size: 12px;
  }

  .submitBtn {
    max-width: 100%;
    height: 52px;
    margin-top: 32px;
    font-size: 15px;
  }

  /* 결과 화면 모바일 최적화 */
  .recommendationSection {
    padding: 20px;
    margin-bottom: 16px;
  }

  .recommendationSection h4 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .recommendationSection li {
    padding: 14px;
    font-size: 14px;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 8px;
  }

  .copyBtn, .saveBtn {
    width: 100%;
    padding: 14px;
  }

  /* 팝업 메시지 모바일 최적화 */
  .popup {
    width: calc(100% - 32px);
    margin: 0 16px;
    bottom: 16px;
    font-size: 14px;
    padding: 14px 20px;
  }
}
